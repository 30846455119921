<template>
    <div id="app">
      <Nav />
      <div class="mt-50"></div>
      <div class="app-hz"></div>
      <div class="america-top-ul flex">
        <div
          class="america-top-li"
          v-for="top in touringTop"
          :key="top.id"
          @click="topData(top)"
          :class="type == top.id ? 'active' : ''"
          :id="type == top.id ? 'europe' : 'type'"
        >
          <img class="top-li-img" :src="top.imgUrl" alt="" />
          <div class="top-li-bot flex center">
            {{ $i18n.locale == "zh" ? top.title : top.title2 }}
          </div>
        </div>
      </div>
      <div class="europe-hz" v-if="$i18n.locale == 'zh'">
        <img src="@/assets/tour/tour-66.png" alt="" class="europe-img" />
        <img src="@/assets/tour/tour-67.png" alt="" class="europe-img" />
        <img src="@/assets/tour/tour-68.png" alt="" class="europe-img" />
        <img src="@/assets/tour/tour-69.png" alt="" class="europe-img" />
        <img src="@/assets/tour/tour-70.png" alt="" class="europe-img" />
        <img src="@/assets/tour/tour-71.png" alt="" class="europe-img" />
        <img src="@/assets/tour/tour-72.png" alt="" class="europe-img" />
      </div>
      <div class="europe-hz" v-else>
        <img src="@/assets/tour/rbEn-1.png" alt="" class="europe-img" />
        <img src="@/assets/tour/rbEn-2.png" alt="" class="europe-img" />
        <img src="@/assets/tour/rbEn-3.png" alt="" class="europe-img" />
        <img src="@/assets/tour/rbEn-4.png" alt="" class="europe-img" />
        <img src="@/assets/tour/rbEn-5.png" alt="" class="europe-img" />
        <img src="@/assets/tour/rbEn-6.png" alt="" class="europe-img" />
      </div>
      <!-- 视频 -->
      <Footer />
    </div>
  </template>
  
  <script>
  import Nav from "@/components/nav.vue";
  import Footer from "@/components/footer.vue";
  
  export default {
    name: "App",
    components: {
      Nav,
      Footer,
    },
    data() {
      return {
        touringTop: [
          {
            id: 0,
            title: "欧洲巡演",
            title2: "Europe Tour 2019",
            imgUrl: require("@/assets/tour/tour-60.png"),
            pageurl: "",
          },
          {
            id: 1,
            title: "韩国巡演",
            title2: "South Korea Tour 2019",
            imgUrl: require("@/assets/tour/tour-61.png"),
            pageurl: "",
          },
          {
            id: 2,
            title: "日本巡演",
            title2: "Japan Tour 2019",
            imgUrl: require("@/assets/tour/tour-62.png"),
            pageurl: "",
          },
          {
            id: 3,
            title: "美国巡演",
            title2: "America Tour 2018",
            imgUrl: require("@/assets/tour/tour-63.png"),
            pageurl: "",
          },
          {
            id: 4,
            title: "2023年 欧洲巡演",
            title2: "Europe Tour 2023",
            imgUrl: require("@/assets/tour/tour-64.png"),
            pageurl: "https://www.suco-europe-tour.com/",
          },
          {
          id: 5,
          title: "2024年 日本巡演",
          title2: "Europe Tour 2024",
          imgUrl: require("@/assets/tour/tour-65.png"),
          pageurl: "",
        }
        ],
        type: 5,
      };
    },
    computed: {},
    created: function () {
      // this.getInitData();
    },
    mounted() {
       document.getElementById("europe").scrollIntoView();
    },
    methods: {
      //  头部导航
      topData(val) {
        if (val.id != 5) {
          if (val.id == 3) {
            this.$router.push({ name: "Usshow" });
          }else if (val.id == 4) {
            this.$router.push({ name: "Europe" });
          }  else {
            this.$router.push({ path: "othershow", query: { type: val.id } });
          }
        }
      },
    },
  };
  </script>
  
  
  
  <style lang="scss" scoped>
  .app-hz {
    width: 100%;
    height: 15px;
  }
  .america-top-ul {
    width: 100%;
    height: 90px;
    margin-bottom: 15px;
    padding-left: 16px;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: auto;
    .america-top-li {
      width: 88px;
      height: 90px;
      background: transparent;
      margin-right: 12px;
      flex-shrink: 0;
      .top-li-img {
        width: 100%;
        height: 50px;
        display: block;
      }
      .top-li-bot {
        width: 100%;
        height: 38px;
        padding: 0 6px;
        line-height: 16px;
        box-sizing: border-box;
        font-size: 13px;
        color: #666666;
        border: 1px solid #835c46;
        border-radius: 0 0 18px 0;
        text-align: center;
      }
    }
    .active {
      .top-li-bot {
        font-size: 13px;
        color: #fff;
        background-color: #835c46;
      }
    }
  }
  .europe-hz{
    width: 100%;
    padding:0 15px;
    box-sizing: border-box;
    margin-bottom: 80px;
  }
  .europe-img {
    width: 100%;
    margin-top: 24px;
  }
  .videoBox {
    width: 100%;
    height: 180px;
    margin-bottom: 46px;
    .video {
      width: 100%;
    }
  }
  </style>
  